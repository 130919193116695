export const environment = {
  production: false,
  environmentName: 'dev',
  apiBaseUrl: 'https://devapi.recytyre.be/api/',
  apiUrl: 'https://devapi.recytyre.be',
  authority: 'https://recytyreb2cdev.b2clogin.com/recytyreb2cdev.onmicrosoft.com/B2C_1A_SI_USERNAME/v2.0',
  metadata: {
    issuer: 'https://recytyreb2cdev.b2clogin.com/7798a8f1-5f55-4350-8db1-d70dd7e47ed4/v2.0/',
    authorization_endpoint: 'https://recytyreb2cdev.b2clogin.com/recytyreb2cdev.onmicrosoft.com/B2C_1A_SI_USERNAME/oauth2/v2.0/authorize',
    token_endpoint: 'https://recytyreb2cdev.b2clogin.com/recytyreb2cdev.onmicrosoft.com/B2C_1A_SI_USERNAME/oauth2/v2.0/token',
    end_session_endpoint: 'https://recytyreb2cdev.b2clogin.com/recytyreb2cdev.onmicrosoft.com/B2C_1A_SI_USERNAME/oauth2/v2.0/logout',
    jwks_uri: 'https://recytyreb2cdev.b2clogin.com/recytyreb2cdev.onmicrosoft.com/B2C_1A_SI_USERNAME/discovery/v2.0/keys'
  },
  client_id: 'efc7d937-ffb8-48fd-8424-9bb1532e36d3',
  redirect_uri: 'https://myrecytyredev.recytyre.be/auth-callback',
  silent_redirect_uri: 'https://myrecytyredev.recytyre.be/silent-renew',
  post_logout_redirect_uri: 'https://myrecytyredev.recytyre.be',
  automaticSilentRenew: true,
  response_type: 'id_token token',
  scope: 'openid https://recytyreb2cdev.onmicrosoft.com/2677eeda-09b0-4bbd-82f6-2505c0acf655/members_api',
  filterProtocolClaims: true,
  loadUserInfo: false,
  buildInfo: "#{buildInfo}#",
  deployInfo: "#{deployInfo}#",
  appInsights: {
    instrumentationKey: '#{instrumentationKey}#'
  }
};